import { Injectable } from '@angular/core';
import { EcaSecurityService, ApiGatewayService, AppContextService, ActivityMonitorService, UrlService, WindowService } from '@drc-eca/eca-components-lib';
import { SecurityServiceUserResponse } from '@drc-eca/eca-components-lib/lib/shared/security-service-user-response.model';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PortalError } from '../../shared/portal-error.model';
import { UserManagementService } from './user-management.service';

@Injectable({
  providedIn: 'root'
})
export class UserSecurityService extends EcaSecurityService {
  static badPasswordMessage = 'There was a problem updating your password. Do not reuse any of your last five passwords. Do not use your first or last name or your username.';
  static expiredPasswordMessage = 'Your link to reset your password has expired. To receive a new password reset link, click on Send a New Link.';
  constructor(protected apiGatewayService: ApiGatewayService,
    protected userManagementService: UserManagementService,
    protected appContextService: AppContextService,
    protected activityMonitorService: ActivityMonitorService,
    protected urlService: UrlService,
    protected windowService: WindowService) {
    super(apiGatewayService, appContextService, activityMonitorService, urlService, windowService);
  }

  updateUserEca(userProfileObj: any): Observable<any> {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/user/${userProfileObj.userid}`;
    const reqOptions = { includeToken: true, cache: { cache: false, clearKey: this.getUserCacheKey(userProfileObj.userid) } };
    return this.apiGatewayService.put<SecurityServiceUserResponse>(url, userProfileObj, reqOptions).pipe(
      tap(user => this.sendPermissionChangeAlert(user.userId).subscribe()),
      switchMap(() => this.resetToken()));
  }

  updateUser(userProfileObj: any): Observable<SecurityServiceUserResponse> {
    if (userProfileObj.password && userProfileObj.currentPassword) {
      return this.userManagementService.changePassword(userProfileObj.userid, userProfileObj.currentPassword, userProfileObj.password).pipe(
        switchMap(() => this.resetToken()),
        tap(user => this.sendPermissionChangeAlert(userProfileObj.userid).subscribe()),
        catchError((error) => {
          // If 401 (from token authenticator - user is not in UUM) then try ECA
          if (error.status === 401) {
            return this.updateUserEca(userProfileObj);
          }
          return throwError(error);
        })
      );
    }

    return this.updateUserEca(userProfileObj);
  }

  sendPermissionChangeAlert(userId: string): Observable<any> {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/email/permissionChange/${userId}`;
    const reqOptions = { includeToken: true, cache: { cache: false } };
    return this.apiGatewayService.post<any>(url, null, reqOptions).pipe(
      map(response => response));
  }

  // need to reset the token, so that the header updates
  // but want to pass user through as a response
  private resetToken() {
    return this.appContextService.getToken().pipe(
      tap(token => this.appContextService.setToken(token))
    );
  }

  activateAccountEca(newCredentialsObj: any): Observable<any> {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/password/reset`;
    const reqOptions = { includeToken: false, cache: { cache: false } };
    return this.apiGatewayService.put<any>(url, newCredentialsObj, reqOptions).pipe(
      map(response => response),
      catchError(err => this.handleHttpError(err, UserSecurityService.badPasswordMessage)));
  }

  activateAccount(userId: string, newCredentialsObj: any): Observable<any> {
    return this.userManagementService.resetPassword(userId, newCredentialsObj.password, newCredentialsObj.token).pipe(
      map(response => response),
      catchError((error) => {
        // If 404 (from lambda - user is not in UUM) then try ECA
        if (error.status === 404) {
          return this.activateAccountEca(newCredentialsObj);
        }
        return this.handleHttpError(error, UserSecurityService.badPasswordMessage);
      }));
  }

  updateWithNewPasswordEca(newCredentialsObj: any): Observable<any> {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/password/reset`;
    const reqOptions = { includeToken: false, cache: { cache: false } };
    return this.apiGatewayService.put<any>(url, newCredentialsObj, reqOptions).pipe(
      catchError(err => this.handleHttpError(err, UserSecurityService.badPasswordMessage)));
  }

  updateWithNewPassword(userId: string, newCredentialsObj: any): Observable<any> {
    return this.userManagementService.resetPassword(userId, newCredentialsObj.password, newCredentialsObj.token).pipe(
      catchError((error) => {
        // If 404 (from lambda - user is not in UUM) then try ECA
        if (error.status === 404) {
          return this.updateWithNewPasswordEca(newCredentialsObj);
        } else if (error.status === 403) {
          return this.handleHttpError(error, UserSecurityService.expiredPasswordMessage);
        }
        return this.handleHttpError(error, UserSecurityService.badPasswordMessage);
      }));
  }

  sendActivateAccountEmail(clientCode: string, token: string) {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/email/newUserRefresh/${token}`;
    return this.apiGatewayService.post(url, { clientCode: clientCode });
  }

  private handleHttpError(err: HttpErrorResponse, custMessage: string): Observable<PortalError> {
    const dataError = new PortalError();
    dataError.errorMessage = err.statusText;
    dataError.friendlyErrorMessage = custMessage;
    dataError.status = err.status;
    return throwError(dataError);
  }
}
