import { Injectable } from '@angular/core';
import { ApiGatewayService } from '@drc-eca/eca-components-lib';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  protected baseUrl: string = environment.userManagementUrl;
  protected endpoint = 'users';
  constructor(private apiGatewayService: ApiGatewayService) { }

  update(userId, userProfile): Observable<any> {
    return this.apiGatewayService.put(`${this.baseUrl}/${this.endpoint}/${userId}`, userProfile);
  }

  updateUum(userId, userProfile): Observable<any> {
    return this.apiGatewayService.put(`${this.baseUrl}/api/v1/${this.endpoint}/${userId}`, userProfile);
  }

  validatePassword(userId: string, newPassword: string, token?: string): Observable<any> {
    let options = {};
    if (token) {
      options = {
        includeToken: false,
        httpOptions: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      };
    }
    return this.apiGatewayService.post(`${this.baseUrl}/api/v1/${this.endpoint}/${userId}/password-validate`, { password: newPassword }, options);
  }

  changePassword(userId: string, previousPassword: string, password: string, token?: string): Observable<any> {
    let options = {};

    if (token) {
      options = {
        includeToken: false,
        httpOptions: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      };
    }

    return this.apiGatewayService.put(`${this.baseUrl}/api/v1/${this.endpoint}/${userId}/password`, { previousPassword: previousPassword, password: password }, options);
  }

  resetPassword(userId: string, password: string, token: string): Observable<any> {
    let options = {};

    options = {
      includeToken: false,
    };

    return this.apiGatewayService.delete(`${this.baseUrl}/api/v1/${this.endpoint}/${userId}/password`, options, { password: password, token: token });
  }

  forgotPassword(email: string, clientCode: string): Observable<any> {
    return this.apiGatewayService.post(`${this.baseUrl}/api/v1/${this.endpoint}/password-forgot`, { email: email, clientCode: clientCode });
  }

  getUserPermissions(userId: string): Observable<any> {
    return this.apiGatewayService.get(`${this.baseUrl}/api/v1/${this.endpoint}/${userId}/uum-permissions`);
  }
}


