import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { of, Subject, zip, Observable } from 'rxjs';
import { UserManagementService } from '../../user/services/user-management.service';

import {
  AppContextService,
  EcaSecurityService,
} from '@drc-eca/eca-components-lib';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  @Input() formTitle: string;
  @Input() formSubtitle: string;
  @Input() saveButtonText: string;
  @Input() userId: string;
  @Input() token: string;
  @Input() cancelButtonText: string;
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  // emits the string value of the new password
  @Output() formSubmit: EventEmitter<string> = new EventEmitter();

  profileForm: UntypedFormGroup;
  newPassword: UntypedFormControl;
  newPasswordConfirm: UntypedFormControl;
  capslock: boolean = false;
  newPasswordCapsLockError: boolean;
  newPasswordConfirmCapsLockError: boolean;
  manageNewPasswordType: string = 'password';
  managePasswordConfirmType: string = 'password';
  productCode: string;
  minPasswordLength: number;
  validationRules$: Observable<any>;
  passwordInputKeyUp$ = new Subject<string>();
  checkStatus = 'INVALID';
  newPasswordTouched = false;

  constructor(
    private ecaSecurityService: EcaSecurityService,
    private appContextService: AppContextService,
    private userManagementService: UserManagementService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    zip(
      this.ecaSecurityService.isUserLoggedIn.pipe(
        concatMap(isLoggedIn => {
          return isLoggedIn ? this.ecaSecurityService.user$ : of(null);
        })
      ),
      this.appContextService.getSelectedProductCode()
    ).subscribe(async ([user]) => {

      if (user) {
        this.userId = user.userId;
      }

      this.validationRules$ = this.passwordInputKeyUp$.pipe(
        debounceTime(333),
        distinctUntilChanged(),
        startWith(''),
        filter(inputText => inputText.length !== 1),
        tap(inputText => {
          if (!this.newPasswordTouched && inputText.length > 0) {
            this.newPasswordTouched = true;
          }
        }),
        switchMap(inputText => this.userManagementService.validatePassword(this.userId, inputText, this.token).pipe(
          tap((validation) => {
            if (validation.description?.title) {
              this.formTitle = validation.description.title;
            }
            if (validation.description?.subtitle) {
              this.formSubtitle = validation.description.subtitle;
            }
            this.checkStatus = validation.status;
          }),
          catchError(err => {
            console.error(err);
            return of({});
          })
        ))
      );

      this.initializeForm();
    });

    // set caps lock warning to off in Egde and IE, since we display our own warning
    this.newPasswordConfirmCapsLockError = false;
    this.newPasswordCapsLockError = false;

    (document as any).msCapsLockWarningOff = true;
    document.addEventListener('keydown', event => {
      this.capslock = event.getModifierState && event.getModifierState('CapsLock');
    });
  }

  initializeForm() {
    this.newPassword = new UntypedFormControl('', {});
    this.newPasswordConfirm = new UntypedFormControl('', [Validators.required]);
    const formGroupValidators = [this.pwdMatchValidator];

    this.profileForm = new UntypedFormGroup({
      newPassword: this.newPassword,
      newPasswordConfirm: this.newPasswordConfirm
    }, formGroupValidators);
  }

  togglePasswordText(inputname: any) {
    switch (inputname) {
      case 'manageNewPasswordType': {
        if (this.manageNewPasswordType === 'password') {
          this.manageNewPasswordType = 'text';
        } else {
          this.manageNewPasswordType = 'password';
        }
        break;
      }
      case 'managePasswordConfirmType': {
        if (this.managePasswordConfirmType === 'password') {
          this.managePasswordConfirmType = 'text';
        } else {
          this.managePasswordConfirmType = 'password';
        }
        break;
      }
    }
  }

  pwdMatchValidator(pswdChangeFrm: any) {
    const passwordInput = pswdChangeFrm['value'];
    if (passwordInput.newPassword === passwordInput.newPasswordConfirm) {
      const errors = pswdChangeFrm.controls['newPasswordConfirm'].errors;
      if (errors && Object.prototype.hasOwnProperty.call(errors, 'passwordNotEquivalent')) {
        pswdChangeFrm.controls['newPasswordConfirm'].setErrors(null);
      }
      return null;
    } else {
      return pswdChangeFrm.controls['newPasswordConfirm'].setErrors({ passwordNotEquivalent: true });
    }
  }

  validateNewPasswordConfirm() {
    return this.newPasswordConfirm.valid || this.newPasswordConfirm.untouched;
  }

  updateCapsLockErrorState(e: any) {
    if (e.type === 'focus') {
      switch (e.currentTarget.name) {
        case 'newPassword': {
          this.newPasswordCapsLockError = true;
          break;
        }
        case 'newPasswordConfirm': {
          this.newPasswordConfirmCapsLockError = true;
          break;
        }
      }
    } else {
      switch (e.currentTarget.name) {
        case 'newPassword': {
          this.newPasswordCapsLockError = false;
          break;
        }
        case 'newPasswordConfirm': {
          this.newPasswordConfirmCapsLockError = false;
          break;
        }
      }
    }
  }

  onSubmitForm(event) {
    this.isLoading.emit(true);
    this.formSubmit.emit(this.newPasswordConfirm.value);
    // prevent the form submit event from propagating
    event.stopPropagation();
  }

  onCancelClick() {
    this.cancel.emit();
  }

}
