<eca-pillar-box class="profile-content">
  <eca-form-column *ngIf="profileForm && (validationRules$ | async) as validationRules">
    <span class="logo-group">
      <eca-drc-insight-logo></eca-drc-insight-logo>
    </span>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmitForm($event)" autocomplete="off" novalidate>

      <h1 class="eca-skip-link-focus-target" tabindex="-1">{{formTitle}}</h1>
      <p>{{formSubtitle}}</p>

      <ng-content select=".ng-content-before"></ng-content>

      <div class="group-form">
        <mat-form-field class="eca-login-component-field eca-password-composite-field reduced-space">
          <mat-label>New Password </mat-label>

          <input matInput formControlName="newPassword" name="newPassword" id="newPassword" [type]="manageNewPasswordType"
            tabindex="0" minlength="8" maxlength="127" required (focus)="updateCapsLockErrorState($event)"
            (focusout)="updateCapsLockErrorState($event)" (keyup)="passwordInputKeyUp$.next($event.target.value)">

          <mat-checkbox matSuffix (change)="togglePasswordText('manageNewPasswordType')">Show Text</mat-checkbox>
          <span [hidden]="!(newPasswordCapsLockError===true && capslock===true)" class="caps-lock-tooltip subtitle-2">
            Caps Lock is on
          </span>
        </mat-form-field>

        <div *ngIf="!newPasswordTouched && validationRules">
          <ul class="new-password-errors">
            <li *ngFor="let item of validationRules.checks"> <span> {{item.rule}}</span></li>
          </ul>
        </div>

        <div *ngIf="newPasswordTouched && validationRules">
          <ul class="new-password-errors validated-password">
            <li *ngFor="let item of validationRules.checks"> <span
                class="{{item.valid===true? 'valid-item': 'invalid-item' }}">
                <span *ngIf="item.valid">&#10003; &nbsp;</span>
                <span *ngIf="!item.valid">&#x2717; &nbsp;</span>
                <span> {{item.rule}}</span></span>
            </li>
          </ul>
        </div>

        <mat-form-field class="eca-login-component-field eca-password-composite-field reduced-space">
          <mat-label>Confirm New Password</mat-label>
          <input matInput formControlName="newPasswordConfirm" name="newPasswordConfirm"
            (focus)="updateCapsLockErrorState($event)" (focusout)="updateCapsLockErrorState($event)"
            [type]="managePasswordConfirmType" required>
          <span [hidden]="!(newPasswordConfirmCapsLockError===true && capslock===true)"
            class="caps-lock-tooltip subtitle-2">
            Caps Lock is on
          </span>
          <mat-checkbox matSuffix (change)="togglePasswordText('managePasswordConfirmType')">Show Text</mat-checkbox>
        </mat-form-field>

        <div>
          <ul *ngIf="newPasswordConfirm.touched" class="new-password-errors validated-password">
            <li
              *ngIf="!validateNewPasswordConfirm() && profileForm.get('newPasswordConfirm').hasError('passwordNotEquivalent')">
              <span class="invalid-item">
                <span>&#x2717; &nbsp;</span>
                <span> New and confirmation passwords do not match</span>
              </span>
            </li>
            <li
              *ngIf="newPasswordConfirm.touched && !(profileForm.get('newPasswordConfirm').hasError('passwordNotEquivalent'))">
              <span class="valid-item">
                <span>
                  <span>&#10003; &nbsp;</span>
                  <span>
                    Confirmation password matches with new password</span>
                </span>
              </span>
            </li>
          </ul>
          <ul *ngIf="!newPasswordConfirm.touched" class="new-password-errors">
            <li> <span> New and confirmation passwords must match</span></li>
          </ul>
        </div>

        <div class="eca-form-column-buttons">
          <button mat-button color="primary" [disabled]="profileForm.invalid || checkStatus !== 'OK'"
            name="btnSubmit" type="submit">
            {{saveButtonText}}
          </button>
          <button mat-button (click)="onCancelClick()" type="button" name="btnCancel"
            *ngIf="cancelButtonText">
            {{cancelButtonText}}
          </button>
        </div>
      </div>

      <ng-content select=".ng-content-after"></ng-content>

    </form>
  </eca-form-column>

</eca-pillar-box>
