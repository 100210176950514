import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import {
  EcaDrcInsightLogoModule,
  EcaFormColumnModule,
  EcaPillarBoxModule,
  EcaSnackbarAlertModule,
} from '@drc-eca/eca-components-lib';

import {NewPasswordComponent} from './new-password.component';

@NgModule({
  imports: [
    CommonModule,
    EcaSnackbarAlertModule,
    EcaPillarBoxModule,
    EcaFormColumnModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    EcaDrcInsightLogoModule
  ],
  exports: [NewPasswordComponent],
  declarations: [NewPasswordComponent],
  providers: [],
})
export class NewPasswordModule {
}
